import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import HomeLayout from '../components/HomeLayout';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import FormRenderer from '../components/Forms/FormRenderer';
import SidebarRenderer from '../components/Forms/SidebarRenderer';
import { hasHelpQuestion } from '../components/Forms/SidebarRenderer';

const FormStyles = styled.div`
  display: flex;
  flex-direction: row;
  /* TODO: we need to adjust the top nav so it doesn't have a bottom-margin of 30px. i didn't want to do it right now because i'd have to update all of the pages */
  margin-top: -30px;

  .iframeHolder {
    flex: 2 0 0;

    iframe {
      border: none;
      width: 100%;
      height: 80vh;
    }
  }

  aside {
    flex: 1 0 0;

    min-width: 200px;
    max-width: clamp(200px, 25vw, 600px);

    border-left: 2px solid black;
    overflow-y: auto;
  }

  @media (max-width: 600px) {
    & {
      flex-direction: column;
      margin-top: 0px;
    }

    .iframeHolder {
      iframe {
        height: calc(100vh - 50px);
        margin-bottom: 70px;
      }
    }

    aside {
      position: fixed;
      bottom: 0px;
      height: calc(60vh + 50px);
      width: 100vw;
      max-width: 100vw;

      border-left: none;
      transform: translate(0, 60vh);

      transition: transform 0.3s;
    }

    aside.expanded {
      transform: translate(0, 0);
    }
  }
`;

const PageStyles = styled.div`
  @media (max-width: 600px) {
    .bf-blog-header {
      display: none;
    }
    .bf-blog-footer {
      display: none;
    }
  }
`;

const EligibilityForm = ({ data }) => {
  const [sidebarText, setSidebarText] = useState('');
  const [iframeReady, setFrameReady] = useState(false);

  // set pluginContent to the parsed HTML created by the formstack-source-plugin
  const pluginContent = data.formstack_html.parsedFormHTML;

  // TODO: rename this so the functionality is clearer -- this allows us to pass the question ID to the sidebar
  const showText = (qID) => {
    setSidebarText(qID);
  };

  useEffect(() => {
    iframeReady && attachButtons();
  }, [iframeReady]);

  const attachButtons = () => {
    const iFrameWrapper =
      document.querySelector('.iframeHolder')?.childNodes?.[0];
    const iFrame =
      iFrameWrapper.contentDocument || iFrameWrapper.contentWindow.document;

    const labels = iFrame.querySelectorAll('.fsRowBody label.fsLabel');
    const legends = iFrame.querySelectorAll('.fsRowBody legend.fsLabel');
    [...labels, ...legends].forEach((el) => attach(iFrame, el));
  };

  const attach = (iFrame, el) => {
    const createHelpButton = iFrame.createElement('input');
    createHelpButton.setAttribute('type', 'button');
    createHelpButton.value = 'i';
    createHelpButton.innerHTML = '<span>i</span>';
    createHelpButton.setAttribute('class', 'bf-help-button');

    let parentNodeID = el.id.replace(/[A-Za-z]/g, '');
    createHelpButton.setAttribute('parentNodeID', parentNodeID);

    let buttonID = 'btn' + parentNodeID;
    createHelpButton.setAttribute('id', buttonID);

    createHelpButton.addEventListener('click', () => {
      showText(buttonID.replace(/[A-Za-z]/g, ''));
    });

    if (hasHelpQuestion(data, parentNodeID)) {
      el.append(createHelpButton);
    }
  };

  // added because gatsby develop occurs in the browser while gatsby build occurs in the Node server [see: https://stackoverflow.com/questions/64308992/gatsby-failed-build-error-window-is-not-available-during-server-side-renderi; https://www.gatsbyjs.com/docs/debugging-html-builds/]
  useEffect(() => {
    window.addEventListener('message', (event) => {
      const data = event.data;
      // console.log('the following button was clicked: ' + data);
    });

    window.onmessage = function (e) {
      const data = e.data;

      switch (data) {
        case 'pageTurn':
          showText();
          break;

        case 'submitted':
          // window.location = window.location.origin + "/eligibility-results";
          break;

        // default:
        //   showText(data.replace(/[A-Za-z]/g, ''));
        //   console.log('on message fired' + data);
        //   break;
      }
    };
    return () => {
      window.removeEventListener('message', (event) => {
        const data = event.data;
        console.log('the following button was clicked: ' + data);
      });
    };
  });

  return (
    <PageStyles>
      <HomeLayout>
        <Helmet title='Eligibility Form' />

        <FormStyles>
          <FormRenderer
            content={pluginContent}
            title='Universal Eligibility Form'
            onLoadComplete={() => setFrameReady(true)}
          />

          <SidebarRenderer content={sidebarText} data={data} />
        </FormStyles>
      </HomeLayout>
    </PageStyles>
  );
};

export default EligibilityForm;

export const HelpDataQuery = graphql`
  query MyFormQuery {
    question_data: allAirtable(filter: { table: { eq: "questions" } }) {
      nodes {
        id
        data {
          formstack_question_id
          rationale
          effect
          guidance
          images {
            url
          }
          image_descriptions
        }
      }
    }
    definitions: allAirtable(filter: { table: { eq: "definitions" } }) {
      nodes {
        id
        data {
          definition_text
          formstack_question_id__from_questions_
        }
      }
    }
    auto_display: allAirtable(
      filter: {
        table: { eq: "questions" }
        data: { auto_display: { eq: true } }
      }
    ) {
      nodes {
        data {
          formstack_question_id
        }
      }
    }
    formstack_html: localFormstackNode {
      # add this query to get our parsed HTML
      id
      formId
      parsedFormHTML
      formHTML
    }
  }
`;
