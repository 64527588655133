import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import styled from 'styled-components';
import cx from 'classnames';
// import HDivider from "./Blocks/HDivider";

function flattenQuestions(questions) {
  let helpData = {};

  const myQuestions = questions.question_data.nodes.map(
    (question) => question.data
  );

  const flatQuestions = _.flatten(myQuestions);
  const myDefinitions = questions.definitions.nodes.reduce((acc, node) => {
    const def = node.data;
    if (def.formstack_question_id__from_questions_ !== null) {
      def.formstack_question_id__from_questions_.forEach((id) =>
        acc[id]
          ? (acc[id] += '\n\n---\n\n' + def.definition_text)
          : (acc[id] = def.definition_text)
      );
    }
    return acc;
  }, {});

  const help = flatQuestions.reduce((acc, node) => {
    const definition = myDefinitions[node.formstack_question_id];
    acc[node.formstack_question_id] = '';

    if (definition)
      acc[node.formstack_question_id] +=
        '## More Information\n\n' + definition + '\n';
    if (node.rationale && node.rationale.trim())
      acc[node.formstack_question_id] +=
        '## Why are we asking this?\n\n' + node.rationale + '\n';
    if (node.effect && node.effect.trim())
      acc[node.formstack_question_id] +=
        '## How your answer will affect your results\n\n' + node.effect + '\n';
    if (node.guidance && node.guidance.trim())
      acc[node.formstack_question_id] +=
        '## Answer Guidance\n\n' + node.guidance + '\n';

    for (const match of acc[node.formstack_question_id].matchAll(
      /\[image:(\d+)\]/gi
    )) {
      const { index } = match;
      const [fullTag, number] = match;
      acc[node.formstack_question_id] =
        acc[node.formstack_question_id].slice(0, index) +
        `![${node.image_descriptions?.split?.(';')?.[number - 1]}]?.(${
          node.images?.[number - 1]?.url
        })` +
        acc[node.formstack_question_id].slice(index + fullTag.length);
    }

    return acc;
  }, {});

  helpData = help;

  return helpData;
}

const SidebarStyles = styled.aside`
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 10px;
  padding-top: 40px;
  font-family: 'Open Sans', sans-serif;

  ul {
    margin-top: 0;
  }

  h2 {
    font-size: 24px;
  }

  img {
    max-width: 100%;
    margin: 10px 0;
  }

  hr {
    border: none;
    border-bottom: 1px solid #bfbfbf;
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &.no-content {
    justify-content: center;
  }

  .empty {
    color: #bfbfbf;
    border: 2px dashed #bfbfbf;
    align-self: center;
    width: 80%;
    padding: 60px 40px;
    text-align: center;
    font-size: 24px;
  }

  .info {
    display: inline-block;
    width: 29px;
    height: 29px;
    background: #bfbfbf;
    color: #ffffff;
    border-radius: 100px;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 28px;
    position: relative;
    top: -5px;
  }

  .expand-button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 50px;
    background: none;
    border: none;
    border-bottom: 1px solid #bfbfbf;
    color: #bfbfbf;
    font-family: 'Open Sans', sans-serif;
  }

  @media (min-width: 600px) {
    & {
      padding-top: 10px;
    }

    .expand-button {
      display: none;
    }
  }
`;

export const hasHelpQuestion = (data, id) => {
  const flatQuestions = flattenQuestions(data);
  return !!flatQuestions?.[id];
};

const SidebarRenderer = ({ content, data }) => {
  const [expanded, setExpanded] = useState(false);
  const flatQuestions = React.useMemo(() => flattenQuestions(data), [data]);
  const showText = (qID) => {
    try {
      if (!qID) return;

      const text = flatQuestions[qID];

      if (text) {
        setExpanded(true);
        return text;
      }

      if (text === '')
        return "## We couldn't find the help article associated with that question. Sorry.";
      else return ''; // TODO: update with default text
    } catch (e) {
      console.error(e);
      return '## An unknown error occurred. Please try again.';
    }
  };

  const text = React.useMemo(() => showText(content), [content]);

  return (
    <SidebarStyles
      className={cx({ 'no-content': !content, expanded: expanded })}
    >
      <button className='expand-button' onClick={() => setExpanded(!expanded)}>
        {expanded ? 'Tap to close' : 'Tap for more info'}
      </button>
      {content ? (
        <ReactMarkdown>{text}</ReactMarkdown>
      ) : (
        <div className='empty'>
          <p>
            Click the <span className='info'>i</span> icon next to the question
            to see more information here!
          </p>
        </div>
      )}
    </SidebarStyles>
  );
};

export default SidebarRenderer;
