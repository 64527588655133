import React from 'react';

const FormRenderer = ({ content, onLoadComplete }) => {
  return (
    <div className='iframeHolder'>
      <iframe srcDoc={content} key='iframe' onLoad={onLoadComplete}></iframe>
    </div>
  );
};

export default FormRenderer;
